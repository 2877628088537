import React from 'react';
import {FormHelperText, Stack} from "@mui/material";

const FormErreur = (props: {
    err?: string | Array<string> | null,
    addedClass?: string,
    prefix?: string
}) => {
    if (!props.err) {
        return null;
    }

    //Si Array on renvoi un element par item
    if (Array.isArray(props.err)) {
        let cnt = 0;
        return (<Stack sx={{width: '100%'}} spacing={2}>
            {props.err.map((value: string) => {
                return <FormErreur addedClass={props.addedClass} key={cnt++} err={value} prefix={props.prefix}/>;
            })}
        </Stack>);
    }

    //renvoi pour item unique
    const prefix = props.prefix ? props.prefix + ': ' : '';
    //return <FormHelperText className={props.addedClass}>{prefix}{props.err}</FormHelperText>;
    return <FormHelperText error className={props.addedClass}>{prefix}{props.err}</FormHelperText>;
}

export default FormErreur;