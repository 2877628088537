import React from 'react';
import Box from "@mui/material/Box";
import {footerText} from "./_LayoutVariables";

const Footer = () => {

    const renderDevElements = () =>
    {
        if (process.env.REACT_APP_ENV === 'prod') {
            return;
        }
        return (<>
                <Box display={"inline"}> - environnement {process.env.REACT_APP_ENV} - </Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'none', xxl: 'inline'}}}>XX-LARGE (XXL)</Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'none', xl: 'inline', xxl: 'none'}}}>X-LARGE (XL)</Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'none', lg: 'inline', xl: 'none'}}}>LARGE (LG)</Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'none', md: 'inline', lg: 'none'}}}>MEDIUM (M)</Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'none', sm: 'inline', md: 'none'}}}>SMALL (SM)</Box>
                <Box  sx={{flexGrow: 1, display: {xs: 'inline', sm: 'none'}}}>X-SMALL (Defaut)</Box>
            </>
        )
    }

    if(!footerText) {
        return null;
    }

    return (
        <footer className="py-0 bg-light fixed-bottom d-flex justify-content-center">
            {footerText}{renderDevElements()}
        </footer>
    );
}
export default Footer;