import {CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {useAuthContext} from "../../context/auth/authContext";
import {Outlet, useNavigate} from "react-router-dom";

const HasRoleRoute = (props: {role: string, redirectTo?: string}) => {
    const authContext = useAuthContext();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if(!authContext.hasRole(props.role)) {
            return navigate(props.redirectTo ? props.redirectTo : '/');
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(loading) {
        return <CircularProgress />
    }

    return <Outlet />;
};

export default HasRoleRoute;