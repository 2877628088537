import React from 'react';
import {menusBackgroundColor, menusTextColor} from "../../_Theme";
import {useLocation} from "react-router";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {Link} from "@mui/material";
import {pagesLinks} from "./_LayoutVariables";
import {useAuthContext} from "../../context/auth/authContext";

const MenuGauche = () => {
    const authContext = useAuthContext();
    const location = useLocation();

    return (
        <Grid sx={{height: "100%", backGround: menusBackgroundColor}} display={"flex"} flexDirection={"column"}>
            {pagesLinks.map((page) => (
                (page.role === undefined || authContext.hasRole(page.role)) &&
                <Link key={page.path} href={page.path}
                      sx={{
                          color: menusTextColor,
                          padding: "0.5rem",
                          cursor: "pointer",
                          textDecoration: "none",
                      }}
                >
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <page.icon sx={{height: "32px"}}
                                       color={location.pathname.includes(page.path) ? "primary" : "inherit"}
                            />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Typography color={location.pathname.includes(page.path) ? "primary" : "inherit"}>
                                {page.title}
                            </Typography>
                        </Box>
                    </Box>
                </Link>
            ))}
        </Grid>
    )
}
export default MenuGauche;