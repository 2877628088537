import {useApiService} from "./api/useApiService";
import {AuthContextInterface} from "../context/auth/AuthContextInterface";
import {AxiosResponse} from "axios";


export interface MessengerStatsInterface {
    [key: string]: number
}

export const useUtilsService = (authContext: AuthContextInterface) => {
    const api = useApiService(authContext);

    const getMessengerStats = async (): Promise<AxiosResponse<MessengerStatsInterface>> => {
        return api.get('messenger-stats');
    }

    return {
        getMessengerStats
    }
}