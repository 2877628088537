import React, {Suspense} from 'react';
import './App.css';
import {CircularProgress, CssBaseline, ThemeProvider} from "@mui/material";
import _Theme from "./_Theme";
import {AuthProvider} from "./context/auth/authContext";
import {Route, Routes} from "react-router-dom";
import {protectedRoutes, RoutesInterface, unprotectedRoutes} from "./_Routes";
import ProtectedRoute from "./components/security/ProtectedRoute";
import PageLayout from "./views/_Layout/SiteLayout";
import NoMatch from "./views/NoMatch";
import HasRoleRoute from "./components/security/HaseRoleRoute";

function App() {
    const makeroutes = (routes: RoutesInterface[]) => {
        return routes.map((route, i) => {
            if (route.index) {
                return (
                    <Route key={i}
                           index
                           element={<Suspense fallback={<CircularProgress/>}>{route.element}</Suspense>}
                    />
                )
            }

            if (route.role) {
                return (
                    <Route key={i}
                           path={route.path}
                           element={<HasRoleRoute role={route.role} redirectTo={route.noRoleRedirectUri}/>}
                    >
                        <Route key={i}
                               path={route.path}
                               element={<Suspense fallback={<CircularProgress/>}>{route.element}</Suspense>}
                        >
                            {route.children && makeroutes(route.children)}
                        </Route>
                    </Route>
                )
            }

            return (
                <Route key={i}
                       path={route.path}
                       element={<Suspense fallback={<CircularProgress/>}>{route.element}</Suspense>}
                >
                    {route.children && makeroutes(route.children)}
                </Route>
            )
        })
    }

    return (
        <ThemeProvider theme={_Theme}>
            <CssBaseline/>
            <AuthProvider>
                <Routes>
                    {/* ROUTES NON PROTEGEE PAR CONNEXION SANS LAYOUT (en gros connexion / pass oublié ....)*/}
                    {makeroutes(unprotectedRoutes)}
                    {/* ROUTES PROTEGEE PAR CONNEXION ET AVEC LAYOUT*/}
                    <Route element={<ProtectedRoute/>}>
                        <Route element={<PageLayout/>}>
                            {makeroutes(protectedRoutes)}
                        </Route>
                    </Route>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;