import React, {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuthContext} from "../../context/auth/authContext";
import {ArrowDropDown, DeveloperBoard} from "@mui/icons-material";
import {Badge, Link} from "@mui/material";
import {getConnectedserName, pagesLinks, titreMenuHaut, userMenuLinks} from "./_LayoutVariables";
import {menusTextColor} from "../../_Theme";
import logo from "../../assets/images/mld-logo.png";
import {useUtilsService} from "../../services/useUtilsService";


const MenuHaut = () => {
    const authContext = useAuthContext();
    const utilsService = useUtilsService(authContext);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [messengerFailed, setMessengerFailed] = React.useState<number>(0);
    const [messengerPending, setMessengerPending] = React.useState<number>(0);

    useEffect(() => {
        utilsService.getMessengerStats().then((response) => {
            if (response.status === 200) {
                setMessengerFailed(response.data.failed ?? 1)
                setMessengerPending((response.data.azure_transport ?? 0) + (response.data.suivi_dependance_transport ?? 0))
            }
        });
    }, []);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (<AppBar position="static">
            <Container maxWidth={false} className={"toolbar"}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={logo} alt={"logo"} width={"24px"}/>
                        &nbsp;
                        {titreMenuHaut}
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pagesLinks.map((page) => (
                                <Link key={page.path} href={page.path} underline="hover" color="inherit">
                                    <MenuItem key={page.path} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                            {page.title}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}/>
                    <Box sx={{flexGrow: 0}}>
                        {messengerFailed > 0 &&
                            <Badge badgeContent={messengerFailed}
                                   color={"error"}>
                                <DeveloperBoard/>
                            </Badge>
                        }
                        {messengerFailed === 0 && messengerPending > 0 &&
                            <Badge badgeContent={messengerPending}
                                   color={"primary"}>
                                <DeveloperBoard/>
                            </Badge>
                        }

                    </Box>
                    <Box sx={{flexGrow: 0.1, display: {xs: 'none', md: 'flex'}}}/>

                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Paramètres">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0, color: menusTextColor}}>
                                {getConnectedserName(authContext)} <ArrowDropDown/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {userMenuLinks.map((item) => (
                                <Link key={item.path} href={item.path} underline="hover" color="inherit">
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">
                                            {item.title}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MenuHaut;