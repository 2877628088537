import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import {useAuthContext} from "../../context/auth/authContext";
import {useAuthService} from "../../services/auth/useAuthService";
import useForm from "../../hooks/form/useForm";
import LoginFormInterface from "../../services/auth/LoginFormInterface";
import MicrosoftLogin from "react-microsoft-login";
import {AuthError} from "msal";
import {Container} from "@mui/material";
import {FormProvider} from '../../context/form/formContext';
import FormErreur from "../../components/form/error/FormErreur";

const Login = () => {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const authService = useAuthService(auth);
    const form = useForm<LoginFormInterface>({}, (data: LoginFormInterface) => {
        return authService.loginUser(data);
    });

    /**
     * init de la fonction d'error handling du form
     */
    useEffect(() => {
        form.setErrorHandler(() => (response: AxiosResponse): { [x: string]: string } => {
            if (response && response.status === 401) {
                return {message: 'Identifiant ou mot de passe incorrect'};
            }

            if (response && response.status === 400 && response.data && response.data.message) {
                if (response.data.message === "The key 'username' must be provided.") {
                    return {username: "L'email est obligatoire"};
                }
                if (response.data.message === "The key 'password' must be provided.") {
                    return {password: "Le mot de passe est obligatoire"};
                }
            }
            return response ? response.data : {};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Redirect vers home quand on aura un token
     */
    useEffect((): void => {
        if (auth.token) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.token]);

    const authHandler = (err: AuthError | null, data: any) => {
        if (data.accessToken) {
            if (form.formData.authToken !== data.accessToken) {
                form.updateFormChamp('authToken', data.accessToken);
            }
        }
    };

    useEffect(() => {
        if (form.formData.authToken) {
            form.handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.formData.authToken]);

    return (
        <FormProvider form={form}>
            <Container>
                <div className="page-center">
                    <MicrosoftLogin clientId={"7f945b5c-c0bf-40aa-b22b-5ca8290df645"} authCallback={authHandler}
                                    graphScopes={["Calendars.ReadWrite.Shared", "MailboxSettings.ReadWrite", "Mail.ReadWrite", "Files.ReadWrite"]}/>
                    <br/>
                    <FormErreur err={form.err.authToken}/>
                </div>
            </Container>
        </FormProvider>
    );
}

export default Login;